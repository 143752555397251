.document-total {
    border-top: 1px solid #EBEBEB;
    width: 25%;
    padding-top: 15px;

    &__list {
        list-style-type: none;
        margin: 0px;
        padding: 0px;
        color: #4A4A4A;

        &__item {
            font-family: 'PoppinsRegular', Arial, sans-serif;
            font-size: 14px;
            display: flex;
            justify-content: space-between;
            margin-top: 5px;

            &__title {
                font-weight: 500;

                &--main {
                    font-size: 15px;
                }
            }

            &__text {

                &--main {
                    font-size: 16px;
                }
            }

            &--main {
                margin-top: 15px;
            }
        }
    }

    // @media screen and (max-width: 991px) {
    //     width: 100%;
    // }
}