.detail-body-subsection {
    padding-left: 20px;
    padding-right: 20px;

    &__item {
        &__title {
            font-family: 'PoppinsRegular', Arial, sans-serif;
            font-size: 14px;
            font-weight: 400;
            color: #687178;
            margin-top: 15px;

            &--print {
                margin-top: 5px;
                font-size: 14px;
            }
        }

        &__content {
            font-family: 'PoppinsRegular', Arial, sans-serif;
            font-size: 14px;
            font-weight: 400;
            color: #000000;
            margin-top: 5px;
            border-bottom: 1px solid #F4F5F6;
            padding-bottom: 3px;
            min-height: 25px;

            &--print {
                font-size: 14px;
                border: 0px;
                margin-top: 5px;
                padding-bottom: 0px;
                min-height: 19px;
            }

            &__list {
                display: flex;
                gap: 10px;
                flex-wrap: wrap;

                &__single-list {
                    text-transform: capitalize;
                    line-height: 25px;
                    padding: 3px 10px;
                    background-color: #F9FAFA;
                    border-radius: 7px;
                }
            }
        }

        &--print{
            width: 45%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            @media screen and (max-width: 991px){
                width: 100%;
            }
        }
    }

    &__separator {
        display: block;
        border-top: 5px solid #F4F5F6;
        margin-top: 30px;
        margin-bottom: 15px;
        width: 100%;

        &:last-child {
            display: none;
        }

        &--print {
            border-top: 1px solid #F4F5F6;
            margin-left: auto;
            margin-right: auto;
            margin-top: 30px;
            margin-bottom: 20px;
            width: 93%;
        }
    }

    &--print {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;;
        padding: 0px 20px;
    }

}


// @media print {
//     html, body {
//       height: initial !important;
//       overflow: initial !important;
//     }
// }
  
// @media print {
//     .page-break {
//         // margin-top: 1rem;
//         // display: block;
//         page-break-after: always;
//     }
// }


