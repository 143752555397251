.setting-notifcation-bar-item {
    padding: 0px 4px;
    position: relative;
    top: 0px;
    right: 0px;
    z-index: 3;

    &__button {
        width: 100%;
        border: 0px;
        background-color: transparent;
        padding: 5px 5px;
        display: flex;
        border-radius: 5px;
        align-items: center;
        justify-content: space-between;

        &:hover {
            background-color: #F4F5F6;
            cursor: pointer;
            text-decoration: none;
            border-radius: 10px;
        }
    }

    &__icon {
        margin-left: 0px;
    }
}