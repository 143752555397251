.select-filter {
    display: flex;
    flex-direction: column;
    padding: 0px 0px;

    &__label {
        font-family: 'PoppinsRegular', Arial, sans-serif;
        font-size: 13px;
        color: #333C44;
        text-transform: capitalize;

        &--none{
            display: none;
        }

        &__required {
            color: #D51B15;
        }
    }

    &__select {

        &--error{
            border: 1px solid #D51B15;
        }
    }
}