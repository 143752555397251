.document-table-container {
    border-bottom: 0px solid #E9E9E1;
}

.document-table-header {
    font-family: "Robot", sans-serif;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 20px;
}

.document-table-wrapper {
    width: 100%;
    overflow-x: auto;
    border: 1px solid #E9E9E1;
    border-bottom: 0px;
    border-radius: 7px;
    margin-bottom: 15px;

    // @media screen and (max-width: 991px) {
    //     border: 1px solid #E9E9E1;
    // }
}

.document-table {
    width: 100%;
    border-collapse: collapse;
    display: block;

    &__header {
        top: 0px;
        z-index: 3;
        border-bottom: 1px solid #F4F5F6;
        // box-shadow: 0 0 0 1px red;
        border-radius: 10px;
        display: block;


        &__item {
            font-family: 'PoppinsRegular', Arial, sans-serif;
            font-size: 15px;
            font-weight: 400;
            padding: 10px;
            position: relative;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            font-weight: 400;
            user-select: none;
            border-radius: 10px;

            &__sort {
                margin-left: 7px;
                display: inline-block;
                border: 0px solid coral;

                &__icon {
                    border: 0px solid coral;
                    margin-top: -3px;
                    ;
                }
            }

            &__resizer {
                position: absolute;
                border-left: 1px solid #EEEEEE;
                background-color: #EEEEEE;
                right: 0px;
                height: 45px;
                width: 1px;
                top: 0;
                touch-action: none;
                z-index: 1;
            }

            &:last-child>&__resizer {
                display: none;
            }

            &:last-child {
                position: relative;
                z-index: 2;
            }

        }

        // @media screen and (max-width: 991px) {
        //     display: none;
        // }
    }

    &__body {
        font-family: 'PoppinsRegular', Arial, sans-serif;
        font-size: 15px;
        color: #1F2747;
        border: 0px solid coral;
        display: block;

        &__row {
            border-bottom: 1px solid #EBEEF0;

            &:hover {
                background-color: #F9FAFA;
            }

            &__cell {
                // padding: 7px;
                // padding-left: 10px;
                // border-right: 1px solid #EBEEF0;
                // text-overflow: ellipsis;
                // white-space: nowrap;
                // overflow: hidden;
                padding: 7px;
                padding-left: 10px;
                border-right: 1px solid #EBEEF0;
                word-wrap: break-word;

                &__header {
                    display: none;

                    // @media screen and (max-width: 991px) {
                    //     display: block;
                    //     font-family: 'PoppinsRegular', Arial, sans-serif;
                    //     font-weight: 500;
                    //     text-align: left;
                    //     border: 0px solid coral;
                    // }
                }

                &__data {
                    display: inline;
                    font-family: 'PoppinsRegular', Arial, sans-serif;
                    font-size: 14px;
                    border: 0px solid coral;
                }

                &:first-child>&__header {

                    // @media screen and (max-width: 991px) {
                    //     display: none;
                    // }
                }

                &:first-child>&__data {

                    // @media screen and (max-width: 991px) {
                    //     display: block;
                    //     text-align: right;
                    // }
                }

                &:first-child {
                    padding: 7px;
                    padding-left: 10px;
                }

                &:nth-child(2) {
                    border: 0px solid coral;
                    padding: 7px;
                    padding-left: 10px;
                    color: #000;
                }

                &:last-child {
                    padding: 7px;
                    padding-left: 10px;
                    border-right: 0px;
                }

                // @media screen and (max-width: 991px) {
                //     display: block;
                //     min-width: 100%;
                //     // border-bottom: 1px solid #EEEEEE;
                //     border-right: 0px;
                //     width: 100% !important;
                //     margin-top: 10px;
                // }
            }

            &:nth-child(even) {

                // @media screen and (max-width: 991px) {
                //     background-color: #F5F5F5;
                // }
            }

            // @media screen and (max-width: 991px) {
            //     display: flex;
            //     flex-direction: column;
            //     flex-wrap: wrap;
            //     margin-bottom: 15px;
            //     border-bottom: 0px;
            //     padding: 5px;
            // }
        }

        // @media screen and (max-width: 991px) {
        //     display: block;
        //     width: 100% !important;
        // }
    }

    // @media screen and (max-width: 991px) {
    //     display: block;
    //     width: 100% !important;
    // }
}

.document-table-footer {
    border: 0px solid coral;
}

.react-table-column-flex-grow-1 {
    flex-grow: 1 !important;
    width: unset !important;
    flex-basis: 5px !important;
    max-width: none !important;
}


@media print {
    .document-table-page-break {
        page-break-inside: auto;
        display: block;
    }
}