.table-count-cell{
    width: 100%;

    &--alert{
        position: absolute;
        top: 15px;
        right: 10px;
        width: 7px;
        height: 7px;
        border: 1px solid coral;
        border-radius: 50%;
        background-color: #E96060
    }
}