.protected-header {
    background-color: #FFFFFF;
    border: 1px solid #E0E0E0;
    
      &__container {
        margin: 0px auto;
        padding: 9.5px 15px !important;

        &__top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: 0px solid coral;

        }

        &__bottom {
            border: 0px solid coral;
        }

        // @media (min-width: 1290px) {
        //     //max-width: 1290px !important;
        //     padding-left: 30px !important;
        //     padding-right: 30px !important;
        // }

        @media (min-width: 1200px) {
            max-width: 1870px !important;
        }
    }

    &__toggle {
        display: none;

        &__button {
            border: 0px;
            background-color: transparent;

            &__icon {
                pointer-events: none;
            }
        }

        @media screen and (max-width:991px) {
            flex: 3;
            display: block;
        }
    }

    &__logo {
        flex: 3;
        margin-top: 2px;

        &__img {

            @media screen and (max-width: 991px) {
                max-width: 120px;
            }
        }

        @media screen and (max-width: 991px) {
            border: 1px;
            margin-top: 0px;
            text-align: center;
        }
    }

    &__nav {
        width: 100%;
        // height: 44px;
        margin-top: 0px;
        position: relative;
        z-index: 1000;
        padding-left: 35px;

        &__items {
            list-style: none;
            display: flex;
            justify-content: start;
            flex-wrap: wrap;
            margin: 0px;

            @media screen and (max-width: 991px) {
                flex: 12;
                flex-direction: column;
                justify-content: start;
            }

        }

        // &__other-nav {
           
        //     &__container {
        //         display: none;

        //         @media screen and (max-width: 991px) {
        //             flex: 1;
        //             display: flex;
        //             flex-direction: column;
        //             list-style: none;
        //             margin: 0px;
        //             background-color: #FFFFFF;
        //             border-top: 1px solid #F4F5F6;
        //         }
        //     }

        //     &__item {

        //         @media screen and (max-width: 991px) {
        //             padding: 10px 10px;
        //             color: #687178;
        //             font-size: 14px;
        //             display: flex;


        //             &__icon {
        //                 margin-right: 15px;
        //             }
        //         }
        //     }
        // }

        @media screen and (max-width: 991px) {
            display: flex;
            width: 100%;
            height: 93vh;
            padding-left: 0px;
            background-color: #FFFFFF;
            position: absolute;
            top: 62px;
            left: 0px;
            flex-direction: column;
            justify-content: space-between;
            transition-property: display, transform;
            transition-duration: 0.4s;
            transform: translate(-1000px);
            border: 0px;
            border-right: 1px solid #F4F5F6;
            width: 100%;
        }

        @media (min-width: 1260px) {
            //max-width: 1290px !important;
            // padding-left: 80px;
        }
    }

    &__setting-notification-account {
        flex: 3;
        
        &__items {
            display: flex;
            justify-content: flex-end;
            margin: 0px;
            padding: 0px;
            align-items: center;
            list-style: none;
        }
    }

}