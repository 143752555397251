.drop-down-button {
    width: auto;
    height: 34px;
    padding: 0 15px;
    background-color: #FFFFFF;
    border: 0px solid #C1C9D2;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'PoppinsRegular', Arial, sans-serif;
    font-size: 15px;
    color: #00030A;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
    border-radius: 20px;

    &__content {
        margin-right: 8px;

        // &--no-icon {
        //     margin-left: 8px;
        // }
    }

    &__icon{
        font-size: 17px;
        color: #747373;

        &--inverted{
            color: #FFF
        }

        &--right-margin{
            margin-right: 5px;
        }
    }

    &--inverted{
        background-color: #155FF6;
        color: #FFF;
        box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.5);
        border-radius: 15px;
    }
}