.home-nav{
    width: 100%;
    padding: 0px 0px;
    padding-bottom: 10px;
    margin-top: 0px;
    
    &__single {
        padding: 10px 10px !important;

        @media screen and (max-width: 991px) {
            padding: 10px 0px;
        }
    
    }

    &__chart {
        padding: 0px !important;
    }
}