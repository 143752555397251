.form-table-external-field {
    padding: 10px 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid coral;
    margin-top: 20px;

    &--margin-top-5 {
        margin-top: 5px
    }

    &--last {
        border-bottom: 0px;
    }

    &--noPadding {
        padding: 0px;
    }

    &__body {
        &__item {
            padding: 5px 0px !important;
            border: 0px solid coral;

            &--noPadding {
                padding: 0px !important;
                padding-right: 20px !important;
            }
        }
    }

    
    @media screen and (max-width: 991px) {
        margin-top: 0px;
    }
}