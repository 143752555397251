.account-display {
    display: flex;

    &__icon-holder {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background-color: #E1EDFE;
        display: flex;
        justify-content: center;
        align-items: center;
    
        @media screen and (max-width: 991px) {
            width: 25px;
            height: 25px;
            padding: 0px;
        }
    }

    &__icon {
        font-size: 20px;
        color: #155FF6;

        @media screen and (max-width: 991px) {
            font-size: 15px;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: start;
        margin-left: 6px;
        justify-content: center;

        &__username {
            font-family: 'PoppinsRegular', Arial, sans-serif;
            font-size: 15px;
            font-weight: 400;
            border: 0px solid coral;
            line-height: 14px;

        }

        &__email {
            display: none;
            font-family: 'PoppinsRegular', Arial, sans-serif;
            font-size: 12px;
            font-weight: 400;
            line-height: 13px;


            @media screen and (max-width: 991px) {
                display: none;
            }
        }

        @media screen and (max-width: 991px) {
            justify-content: center;
        }
    }


}