.detail-body-print {
    background-color: #EBEEF0;
    padding: 30px 10% !important ;
    margin-top: 20px;
    margin-bottom: 70px;
    position: relative;
    border: 0px solid coral;
    display: flex;
    justify-content: center;


    &__spinner {
        margin: 0px auto;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        margin-top: -20px;
    }

    &__error {
        padding: 40px;
        background-color: #FFFFFF;
        border-radius: 10px;
    }

    &__print-header {
        border-bottom: 1px solid #CCCCCC;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding-bottom: 10px;

        &__title{
            font-family: 'PoppinsRegular', Arial, sans-serif;
            font-size: 22px;
            text-transform: uppercase;
        }
    }

    &__a4{
        background-color: #FFFFFF;
        width: 100%;
        padding: 50px;
    }

    @media (min-width: 1200px) {
        max-width: 1260px !important;
    }
}