.modal-headers{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #FFFFFF;
    padding: 10px 25px;
    border-bottom: 1px solid #F4F5F6;
    
    &__title{
        font-family: 'PoppinsRegular', Arial, sans-serif;
        font-size: 18px;
        color: #1F272E;
        margin-top: 9px;
    }
    &__action{
        background: transparent;
        border: 0px;
        text-align: right;
    }
}   