.detail-body-section {
    padding: 10px 0px;
    border: 0px solid green;
    background-color: #FFFFFF;
    border-radius: 10px;
    box-shadow: 0px 0px 1px #A1A1A1;
    margin-top: 30px;

    &:first-child {
        margin-top: 0px;
    }

    &__error-empty{
        font-size: 14px;
        text-transform: capitalize;
        padding-left: 20px;
        padding-right: 20px;
    }

    &--print{
        box-shadow: 0px 0px 0px;
        margin-top: 10px;
    }
}
