.list-table-local-filter {
    margin-left: -5px;
    
    &__title {
        font-family: 'PoppinsRegular', Arial, sans-serif;
        font-size: 15px;
        margin-bottom: 3px;
        margin-left: 5px;
    }

    &__fields {
        border: 0px solid green;
    }
}