* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: 'PoppinsRegular';
  src: url('./assets/fonts/Poppins-Regular.ttf') format('truetype');
}

body{
  font-family: 'PoppinsRegular', Arial, sans-serif;
  font-size:16px;
  background-color: #F8F9FA !important;
  letter-spacing: 0.3px;
  -webkit-user-scalable: none;
  line-height: 1.4
}

a {
  text-decoration: none;
}

