.home-body {
    padding: 10px 0px;

    &__card{

        &__single{
            padding: 0px !important;
        }
    }

    @media screen and (max-width: 991px) {
        width: 100%;
    }
}