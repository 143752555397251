.home {
    border: 0px solid coral;
    max-width: 1290px;
    margin: 0px auto;
    padding-bottom: 40px;

    &__body {
        padding: 0px 0px;
        border: 0px solid green;
    }
}