.help-header {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;

    &__tools {
        display: flex;
        gap: 8px;

        &__filter-actions {
            display: flex;
            gap: 8px;
            justify-content: end;

            &__filter {
                min-width: 310px;

                @media screen and (max-width: 991px) {
                    width: 100%;
                    position: absolute;
                    left:0px;
                    top:0px;
                    z-index: 1;
                    padding: 10px 14px;
                }
            }

            @media screen and (max-width: 991px) {
                width: 100%;
                position: absolute;
                left:0px;
                top:35px;
                z-index: 1;
                padding: 0 14px;
            }
        }
    }
    @media screen and (max-width: 991px) {
        position: relative;
        padding: 0px 10px !important;
        z-index: 2;
    }

    @media (min-width: 1200px) {
        max-width: 1290px !important;
    }
}