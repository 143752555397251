.protected-route{

    &__error{
        max-width: 1290px;
        margin: 0px auto;
        padding: 10px 10px;

        &__box-wrapper{
            padding: 20px 20px;
            padding-bottom: 10px;
            background-color: #FFFFFF;
            border-radius: 10px;
            box-shadow:  0px 0px 4px rgba(25, 39, 52, 0.1);;
            margin-top: 40px;
        }
    }
}