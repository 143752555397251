.log-in-form-group{
  margin:0px 22px;

  &__label{
    font-family: 'PoppinsRegular', Arial, sans-serif;
    font-size: 16px;
    color: #00030A;
    line-height: 14px;
  }

  &__input{
      border: 1px solid #CBD2E1;
      width: 100%;
      height: 48px;
      text-indent: 10px;
      border-radius: 10px;

      &:focus{
        outline: none;
        border:1px solid #0074E3;
        box-shadow: 0 0 3px #0074E3;
      }   
  }

  &--initial-focus{
    border:1px solid #0074E3;
    box-shadow: 0 0 3px #0074E3;
  }
}