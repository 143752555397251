.search-field{
    margin-top: 10px;
    width:100%;
    overflow: hidden;
    border:0px solid coral;
    display: flex;

    &__input{
        width: 89%;
        height: 31px;
        font-size: 13px;
        border-radius: 5px 0px 0px 5px;
        text-indent: 15px;
        color: #687178;
        background-color: #F4F5F6;
        border: 0px;
        
    }
    &__input::placeholder{
        font-family: 'PoppinsRegular', Arial, sans-serif;
        color: #687178;
    }
    &__input:focus{
        outline: 0px;
    }
    &__button{
        width:36px;
        height: 31px;
        border: 0px;
        border-radius: 0px 5px 5px 0px;
        background-color: #687178;
        
    }
    &__search-icon{
        width:15px;
    }
}