.spinner{
    display: inline-block;
    border: 0px solid coral;
    padding: 5px 5px;
    
    &__img{
        animation: loading-spinner 1s linear infinite;
    
        @keyframes loading-spinner {
            from {
                transform: rotate(0deg);
            }
            to {
                transform: rotate(360deg);
            }
        }

        &--small{
            width: 13px;
        }
        &--medium{
            width: 20px;
        }
        &--large{
            width: 30px;
        }
    }

    &--wide{
        border: 0px solid coral;
        width: 100%;
        display: inline-block;
        text-align: center;
        padding: 20px;
    }

    &--left{
        text-align: left;
    }  
    &--center{
        text-align: center;
    }
    &--right{
        text-align: right;
    }  
  }