.table-cell-status {
    font-family: 'PoppinsRegular', Arial, sans-serif;
    font-size: 13px;
    padding: 5px 12px;
    border-radius: 12px;
    text-transform: capitalize;
    font-weight: 500;

    &--danger {
        color: #f44336;
        border: 1px solid #f44336;
    }

    &--warning {
        color: #FF8C00;
        border: 1px solid #FF8C00;

    }

    &--success {
        color: #006400;
        border: 1px solid #006400;

    }
}