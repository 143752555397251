.document-body-header-section {
    padding: 0px !important;
    padding-bottom: 10px !important;
    min-width: 190px;

    &__title {
        font-family: 'PoppinsRegular', Arial, sans-serif;
        font-size: 16px;
        text-transform: uppercase;
        font-weight: 500;

        &--large {
            font-size: 22px
        }
    }

    &__sub-title {
        font-family: 'PoppinsRegular', Arial, sans-serif;
        font-size: 16px;
        font-weight: 400;
    }

    &__barcode {
        border: 0px solid coral;

        // @media screen and (max-width: 991px) {
        //     display: none;
        // }
    }

    &__list {
        list-style-type: none;
        margin: 0px;
        padding: 0px;
        font-size: 14px;
        text-align: left;

        &__title-text{
            display: flex;
            justify-content: space-between;
            border: 0px solid coral;

            &__title{
                font-weight: 500;
            }
        }
    }

    &--right {
        text-align: right
    }

    // @media screen and (max-width: 991px) {
    //     &:first-child{
    //         display: none;
    //     }
    // }
}