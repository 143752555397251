.home-chart{
    margin-top: 30px;
    border: 0px solid coral;
    
    &__date{
        display: flex;
        justify-content: end;
        padding-right: 25px;
        gap:5px;
    }

    &__chart {
        margin-top:30px;
        border: 0px solid coral;
    }
}