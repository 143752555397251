.button-spinner{
  width: 30px;
  
  &__img{
  animation: loading-spinner 1s linear infinite;

    @keyframes loading-spinner {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
  }    
}
