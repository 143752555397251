.log-in-custom-button{
    width: 100%;
    height: 48px;
    color: #FFF;
    background-color: #155FF6;
    font-family: 'PoppinsRegular', Arial, sans-serif;
    font-size: 15px;
    border:1px solid #155FF6;
    border-radius: 30px;

    &--disabled{
        background-color: #61B5EB;
    }
}