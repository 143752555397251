.stock-detials {
    font-family: 'PoppinsRegular', Arial, sans-serif;
    max-width: 100%;
    background-color: #f9f9f9 ;
    color: #FFF;
    border-radius: 12px;
    margin-left: 10px;
    padding: 10px 5px;

    &__total {
        border-bottom: 0px solid #FFF;
        padding: 10px 20px;
        margin-bottom: 10px;
   
        &__single {
            display: flex;
            flex-direction: column;

            &__title {
                font-size: 16px;
                display: flex;
                align-items: center;
                color: #00030A
            }

            &__content {
                font-family: 'Roboto', Arial, sans-serif;
                font-size: 28px;
                color: #00030A;

                &__non-stock{
                    font-family: 'PoppinsRegular', Arial, sans-serif;
                    font-size: 16px;
                    color: #00030A;
                }
            }
        }

        &--noBottomBorderPadding {
            border-bottom: 0px;
            padding-bottom: 0px;
        }
    }

    &__branch {
        border: 0px solid coral;
        padding: 10px 20px;
        padding-top: 0px;

        &__title {
            font-size: 18px;
            background-color: #FFF;
            border-radius: 10px 10px 0px 0px;
            padding-top: 10px;
            padding-left:20px;
            padding-bottom: 3px;
            color: #00030A;
            border-bottom: 1px solid #EEE;

            &__subtitle {
                font-size: 13px;
                margin-left: 5px
            }
        }

        &__breakdown {
            border: 0px solid coral;
            background-color: #FFF;
            border-radius: 0px 0px 10px 10px;
            margin-top: 0px;
            padding: 0px 10px;
            padding-bottom: 10px;
            display: flex;
            justify-content: space-between;
            

            &__single {
                // display: flex;
                // justify-content: space-between;
                color: #33386C;
                line-height: 30px;
                padding: 0px 10px;

                &:last-child {
                    border-bottom: 0px;
                }

                &__title {
                    font-size: 15px;
                    color: #555;
                }

                &__content {
                    font-family: 'Roboto', Arial, sans-serif;
                    font-size: 15px;
                    color: #00030A;
                }

                @media screen and (max-width: 991px) {
                    display: flex;
                    justify-content: space-between;
                    border-bottom: 1px solid #F9F9F9;
                }
            }

            @media screen and (max-width: 991px) {
                display: block;
                border-bottom: 0px;
               
            }
        }
    }
}