.list-table-body {
    padding: 0px 15px !important;
    width: 100%;
    position: relative;
    top: 20px;
    left: 0px;
    z-index: 1;

    &__wrapper {
        background-color: #FFFFFF;
        min-height: calc(100vh - 200px);
        padding: 20px 27px;
        border-radius: 10px;
        background-color: #ffffff;
        border-radius: 12px;
        border: 1px solid #EDEFF0;

        @media screen and (max-width: 991px) {
            min-height: calc(100vh - 175px);
            border-radius: 0px;
            padding: 0px 0px;
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
    }

    &__filter {
        // border-bottom: 1px solid #F4F5F6;
        margin-bottom: 15px;
        padding-bottom: 15px;
    }

    &__deactivate {
        // border-bottom: 1px solid #F4F5F6;
        margin-bottom: 15px;
        padding-bottom: 15px;
        display: flex;
        align-items: center;

        @media screen and (max-width: 991px) {
            display: none;
        }
    }

    &__spinner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &__error {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        @media screen and (max-width: 991px) {
            width: 100%;
        }
    }

    @media screen and (max-width: 991px) {
        padding: 0px !important;
        display: block;
        top: 62px;
    }

    @media (min-width: 1200px) {
        max-width: 1870px !important;
    }
}