.checkbox {

    &__label{
        display: flex;
        align-items: center;
        margin-top: 5px;

        &__text {
            font-family: 'PoppinsRegular', Arial, sans-serif;
            font-size: 14px;
            color: #727171;
            margin-left: 5px;
            user-select: none;
        }   
    }
}