.custom-button {
    width: auto;
    height: 33px;
    padding: 0px 15px;
    color: #FFF;
    background-color: #155FF6;
    font-family: 'PoppinsRegular', Arial, sans-serif;
    font-size: 15px;
    border: 1px solid #155FF6;
    border-radius: 15px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);

    &__icon {
        margin-right: 5px;
        margin-top: -1px;
        font-size: 16px;
        color: #FFFFFF;

        &--inverted {
            color: #747373;
        }
    }

    &--inverted {
        background-color: #FFF;
        color: #00030A;
        border: 1px solid #FFFFFF;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
        border-radius: 15px;
    }

    &--disabled {
        border: 0px;
        box-shadow: 0px 0px 0px;
        background-color: #155FF6;
        opacity: 0.5;
        color: #FFF;
    }
}