.form-table-inline-button {
    height: 20px;
    background-color: transparent;
    border: 0px solid #C1C9D2;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;

    &__icon{
        margin-right: 5px;
    }
    &__text{
        font-size: 13px;
    }
}