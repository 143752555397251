.home-nav-item {
    background-color: #FFFFFF;
    padding: 3px 0px;
    border-radius: 12px;
    // box-shadow: 0 0 6px rgba(0, 0, 0, 0.1); 
    border: 1px solid #EDEFF0;

    @media screen and (max-width: 991px) {
        min-height: auto;
    }

    &__header {
        border-bottom: 1px solid #F3F3F3;
        display: flex;
        align-items: center;
        gap: 10px;
        margin-top: 10px;
        padding: 15px 30px;
        border-radius: 50px 50px 0px 0px;
        background-color: #FFF;

        &__icon {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #0095F6;
            display: none;

            &__item {
                font-size: 28px;
                color: #FFFFFF;
            }
        }

        &__title {
            font-family: 'PoppinsRegular', Arial, sans-serif;
            font-size: 20px;
            font-weight: 600;
            color: #00030A;
        }
    }

    &__body {
        margin-top: 15px;
        list-style: none;
        padding: 5px 30px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 20px;

        &__item {
            border-bottom: 0px solid #F1F1F1;
            padding: 7px 0px;
            flex: 0 0 calc(33.33% - 20px);

            &__link {
                display: flex;
                align-items: center;
                font-family: 'PoppinsRegular', Arial, sans-serif;
                font-size: 15px;
                font-weight: 600;
                color: #00030A;
                width: 100%;
                gap: 3px;
                padding: 7px 8px;
                border-radius: 0px;

                &__icon {
                    background-color: #F8F9FA;
                    padding: 10px;
                    font-size: 47px;
                    margin-right: 10px;
                    pointer-events: none;
                    color: #155FF6;
                    border-radius: 10px;
                    align-self: flex-start;
                }

                &__icon-arrow {
                    margin-left: auto;
                    font-size: 16px;
                    color: #155FF6;
                }

                &:hover {
                    text-decoration: none;
                    color: #1F2747;
                    background-color: #F4F5F6;
                    border-radius: 7px;
                }

                &__text {
                    font-family: 'PoppinsRegular', Arial, sans-serif;

                    &__desc {
                        font-size: 14px;
                        font-weight: 400;
                        color: #777;
                        margin-top: 3px;

                        @media screen and (max-width: 991px) {
                            display: none;
                        }
                    }
                }

                @media screen and (max-width: 991px) {
                    width: 100%;
                }
            }

            &:last-child {
                border-bottom: 0px;
            }
        }

        @media screen and (max-width: 991px) {
            flex-direction: column;
            gap: 0px;
        }

    }
}