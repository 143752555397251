.select-field {
    display: flex;
    flex-direction: column;
    padding: 3px 0px;

    &__label {
        font-family: 'PoppinsRegular', Arial, sans-serif;
        font-size: 15px;
        color: #00030A;
        text-transform: capitalize;

        &--none{
            display: none;
        }

        &__required {
            color: #D51B15;
        }
    }

    &__select {

        &--error{
            border: 1px solid #D51B15;
        }
    }
}