.add-remove-button{
    font-family: 'PoppinsRegular', Arial, sans-serif;
    font-size: 13px;
    padding: 7px 13px;
    color: #0074E3;
    background-color: #FFF;
    font-family: 'PoppinsRegular', Arial, sans-serif;
    border:1px solid #D8DDE6;
    border-radius: 10px;
    display: flex;
    align-items: center;

    &--disabled{
        background-color: #77a7d4;
    }
    &__icon{
        margin-right:4px;
    }
    &__remove{
        background-color: #EC645E;
        color: #FFF;
        border:0px;
    }
}