.list-global-filter {
    background-color: #FFFFFF;
    height: 36px;
    border-radius: 20px;
    display: flex;
    padding: 0px 10px;
    max-width: 450px;
    border: 1px solid #CACBCB;
    // box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5);
    // box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.4);

    &__icon {
        width: 15px;
        pointer-events: none;
    }

    &__field {
        font-family: 'PoppinsRegular', Arial, sans-serif;
        font-size: 14px;
        text-indent: 10px;
        border: 0px;
        background-color: #FFFFFF;
        height: 33px;
        width: 100%;
        border-radius: 20px;

        &:focus {
            outline: 0px;
        }
    }

    @media screen and (max-width: 991px) {
        max-width: 100%;
    }

}