$opacity-none: rgba(0,0,0,0);
$opcity-show: rgba(0,0,0,0.35);
$opcity-duration: 300ms;
$transofrm-duration: 100ms;

.alert-box{
    position: fixed;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    left: 0px;
    right:0px;
    top: 0;
    bottom: 0px;
    background-color: $opacity-none;
    display: flex;
    justify-content: center;
    z-index: 1000;

    &-enter{
        background-color: $opacity-none;
    }
    &-enter-active{
        background-color: $opcity-show;
        transition: background-color $opcity-duration;
    }
    &-enter-done{
        background-color: $opcity-show;
        transition: background-color $opcity-duration;
    }
    &-exit{
        background-color: $opcity-show;
    }
    &-exit-active{
        background-color: $opacity-none;
        transition: background-color $opcity-duration linear;
    }
    &-exit-done{
        background-color: $opacity-none;
    }

    &__dialog{
        border: 0px solid coral;
        width: 573px;
        max-height: 243px;
        margin-top:4%;
        background-color: #FFF;
        transform: translateY(-1000px);
        border-radius: 15px;
        margin-bottom: 50px;
        position: relative; 
        overflow-y: hidden ;

        @media screen and (max-width: 991px){
            border: 0px solid green;
            margin-top: 0px;
            margin-bottom: 0px;
            border-radius: 0px;
        }

        &__header{
            border-bottom: 1px solid #F4F5F6;
            padding: 20px;
            font-family: 'PoppinsRegular', Arial, sans-serif;
            font-size: 15px;
            color: #000;
            
        }

        &__body{
            border-bottom: 1px solid #F4F5F6ed;
            height: 120px;
            padding: 20px;
            font-family: 'PoppinsRegular', Arial, sans-serif;
            font-size: 14px;
            color: #1F272E;
            
        }

        &__footer{
            border: 0px solid green;
            height:55px;
            padding: 15px 10px;
            font-family: 'PoppinsRegular', Arial, sans-serif;
            font-size: 14px;
            color: #1F272E;
            display: flex;
            justify-content: right;
            gap: 10px;

            &__spinner{
                margin-right: 15px;
            }
        }

    }
    &-enter-done &__dialog {
        transform: translateY(0px);
        transition: transform $transofrm-duration linear;
    }
    &-exit &__dialog{
        transform: translateY(-1000px);
        transition: transform $transofrm-duration linear;
    }
}

