.log-in {
    width: 100%;
    height: 95vh;
    background-color: #F5F9FF;

    &__wraper {
        height: 95vh;
    }

    &__logo-img-wraper {
        padding: 28px 0;
    }

    &__logo-img {
        width: 250px;
        margin: 0 auto;
        display: block;
    }
}