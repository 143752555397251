.detail-body {
    padding: 0px 0px !important;
    margin-top: 20px;
    margin-bottom: 70px;
    position: relative;

    &__spinner {
        margin: 0px auto;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 30px;
    }

    &__error {
        padding: 40px;
        background-color: #FFFFFF;
        border-radius: 10px;
    }

    &__print-header {
        border-bottom: 1px solid #CCCCCC;
        display: none;
        flex-direction: column;
        align-items: flex-end;
        padding-bottom: 10px;

        &__title{
            font-family: 'PoppinsRegular', Arial, sans-serif;
            font-size: 22px;
            text-transform: uppercase;
        }
    }

    @media (min-width: 1200px) {
        max-width: 1260px !important;
    }
}