.title-header{
    font-family: 'PoppinsRegular', Arial, sans-serif;
    font-size: 22px;
    display: inline-block;
 

    &__button{
        border: 0px;
        background-color: transparent;
    }

    &__icon{
        border: 0px solid coral;
        margin-right: 12px;
        margin-top: -3px;
        cursor: pointer;

        @media screen and (max-width: 991px) {
            display: none;;
        }
    }

    &__text {
        border: 0px solid coral;
        color: #00030A;
        font-weight: 600;
        margin-top: 5px;

        @media screen and (max-width: 991px) {
            font-size: 20px;
        }
    }

    @media screen and (max-width: 991px) {
        font-size: 18px;
    }
}