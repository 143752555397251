.general-modal__body{
    width: 100%;
    padding: 20px 20px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    position: absolute;
    top: 58px;
    bottom: 30px;
    left: 0;
    right: 0;

    &__spinner{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
}