.form-table-total{
    min-width: 300px;
    background-color: #f7f7f7;
    border-radius: 0px 0px 7px 7px;
    padding: 10px;

    &__single{
        display: flex;
        justify-content: space-between;
        padding: 5px 0px;
        border-top: 0px solid #F5F9FF;

        &:first-child{
            border-top: 0px;
        }

        &__title{
            font-family: 'PoppinsRegular', Arial, sans-serif;
            font-size: 15px;
            color: #00030A;
        }

        &__content{
            font-family: 'PoppinsRegular', Arial, sans-serif;
            font-size: 15px;
            font-weight: 500;
            color: #30360A;
        }
    }

    &--inverted{
        background-color: #FFFFFF;
    }

    @media screen and (max-width: 991px) {
        min-width: auto;
        width: 210px;
    }
}