.form-field {
    display: flex;
    flex-direction: column;
    padding: 3px 0px;

    &__label {
        font-family: 'PoppinsRegular', Arial, sans-serif;
        font-size: 15px;
        color: #00030A;

        &__required {
            color: #D51B15;
        }
    }

    &__input {
        border: 1px solid #CBD2E1;
        height: 36px;
        background-color: #FFF;
        text-indent: 10px;
        border-radius: 10px;
        font-size: 15px;

        &--error {
            border: 1px solid #D51B15;
        }

        &--disabled {
            background-color: #FFF;
            color: #333;

            &:hover {
                cursor: not-allowed;
            }
        }
    }

    &__input:focus {
        outline: 2px solid #3084B7;
    }
}