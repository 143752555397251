.log-in-form {
    width: 100%;
    height: auto;
    background-color: #FFF;
    border-radius: 16px;
    padding: 35px 10px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);

    &__header {
        font-family: 'PoppinsRegular', Arial, sans-serif;
        font-size: 40px;
        font-weight: 600;
        padding: 0px 20px;

        &__subtext {
            font-family: 'PoppinsRegular', Arial, sans-serif;
            font-size: 14px;
            font-weight: 400;
            margin-top: 3px;
        }
    }

    &__field {
        margin: 25px 0px;
    }

    &__buttons {
        margin: 0 22px;
        margin-top: 10px;
    }

    &__radio {
        margin: 0 22px;
        margin-top: 10px;
    }

    &__general-error {
        margin: 0 20px;
        margin-top: 10px;
    }

    &__input-error {
        margin: 0 20px;
    }

    @media screen and (max-width: 991px) {
        padding: 35px 0px;
    }
}