.form-table-button{
    width: 100%;
    height: 50px;
    padding: 0px 13px;
    color: #1F272E;
    background-color: #FFFFFF;
    font-family: 'PoppinsRegular', Arial, sans-serif;
    font-size: 15px;
    border:7px solid #f7f7f7;
    border-radius: 0 0 0px 7px;

    &:hover{
        background-color: #fefefe;
        border:7px solid #f2f2f2;
        border-radius: 0 0 0px 7px;
    }

    &__icon{
        font-size: 16px;
        margin-top: -3px
    }

    &__text{
        border: 0px solid coral;
        margin-left: 4px;
    }

    &--disabled{
        background-color: #D8DDE6 !important;
        color: #1F272E;
    }
    &--inverted{
        background-color: #FFF;
        color: #687178;
        border: 1px solid #FFFFFF;
        box-shadow: 0px 0px 3px #999;
    }

    &--left-margin{
        margin-left: 10px;
    }
}