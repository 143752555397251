.local-filter-field{
    font-family: 'PoppinsRegular', Arial, sans-serif;
    font-size: 14px;
    border: 0px solid coral;
    background-color: #F4F5F6;
    border-radius: 10px;
    width: 140px; 
    height: 30px;
    text-indent: 10px;
    margin: 5px 5px;

    // &:first-child{
    //     margin-left: 0px;
    // }

    &:focus{
        outline: 1px solid #0095F6;;
    }
}