.nav-item {
    position: relative;
    top: 0px;
    left: 0px;
    border: 0px solid coral;

    &__link {
        width: 100%;
        font-family: 'PoppinsRegular', Arial, sans-serif;
        border: 0px;
        background-color: transparent;
        padding: 10px 7px;
        display: flex;
        font-size: 14px;
        font-weight: 600;
        color: #202229;
        border-radius: 5px;
        align-items: center;
        justify-content: space-between;
       
        @media screen and (max-width: 991px) {
            font-size: 16px;
        }
    }

    &__link:hover {
        color: #155FF6;
        cursor: pointer;
        text-decoration: none;
    }

    &__icon {
        font-size: 14px;
        margin:0px 5px;
        pointer-events: none;
        transform: rotate(0deg);
    }

    &__placeholder{
        width: 8px;
    }
}