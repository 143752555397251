.error-box {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__container {

        &__name {
            font-family: 'PoppinsRegular', Arial, sans-serif;
            font-size: 22px;
            font-weight: 600;
            color: #333C44;
            text-transform: capitalize;
            text-align: center;
            padding: 10px;
        }

        &__message {
            font-family: 'PoppinsRegular', Arial, sans-serif;
            font-size: 15px;
            text-align: center;
            padding: 10px;
            padding-top: 0px;
            max-width: 500px;
        }
    }
}